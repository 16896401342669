import moment from "moment";
import smalltalk from "smalltalk";

/* eslint eqeqeq: "off" */

function alert(msg) {

    smalltalk.alert("VESO888 cho biết", msg.toString());
}
async function fetchSeo(page) {
    // try{
    // var json=await fetch("https://api.iloto.com.vn/api/v1/admin/seo");
    // var data =await json.json();
    // return JSON.parse( data[page]);
    // } catch(err){
    //     console.log(err);
    //     return {};
    // }
    return require(`../assets/seo/${page.split('.')[0]}.js`).default;
}
function confirm(msg, callback) {
    smalltalk.confirm("VESO888 cho biết", msg.toString())
        .then(() => {
            if (callback) return callback(true)
        })
        .catch(() => {
            if (callback) return callback(false)
        })
}

export function isEmpty(data) {
    if (data === "" || data === null || data === undefined)
        return true;
    if (Array.isArray(data)) {
        if (data.length === 0)
            return true;
    }
    if ((typeof data) === "object") {
        for (let key in data) {
            if (data.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    return false;
}

export function formatValue(value, length = 2) {
    if (isEmpty(value))
        return "";
    try {
        let num_string_arr = value.toString().split("");
        return Array(length - num_string_arr.length).fill("0").concat(num_string_arr).toString().replace(/,/g, "");
    } catch (e) {
        return value;
    }
}

export function copyState(state) {
    return JSON.parse(JSON.stringify(state));
}

let lotteryConfig = {
    EXPRESS_DELIVERY_FEE: 100,
    NORMAL_DELIVERY_FEE: 50,
    KENO_TIME_LIMIT: 1,
    GLOBAL_TIME_LIMIT: "17:30:30",
    WITHDRAWAL_ONLINE_FEE: 0,
    MINIMUM_AMOUNT_PER_TIME: 100,
    MAXIMUM_AMOUNT_PER_TIME: 20000,
    OPEN_TIME: "08:30:00",
    CLOSE_TIME: "21:50:00",
};

export function generateCountDownDate(productId) {
    /*
    * Get config
    * */
    let state = {};

    try {
        state = JSON.parse(localStorage.getItem("state"));
        if (state) {
            if (typeof (state.lotteryConfig) === "object") {
                lotteryConfig = {
                    ...lotteryConfig,
                    ...state.lotteryConfig
                }
            }
        }
    } catch (e) {

    }

    // Init
    const sampleType = ["", "mega645", "power655", "max4d", "max3d", "max3d", "keno"];
    let type = sampleType[productId];
    let today = moment();

    // KENO
    if (type === "keno") {
        let closeTimeKeno = lotteryConfig.CLOSE_TIME.split(":");
        let openTimeKeno = lotteryConfig.OPEN_TIME.split(":");
        let today_finish = moment();
        today_finish.set("hour", 21);
        today_finish.set("minute", 57);
        today_finish.set("second", 0);
        var s = today.diff(today_finish);
        if ((today.hours() >= 21 && today.minutes() >= 48) || today.diff(today_finish) > 0) { // Quá giờ bán keno -> Chuyển sang ngày hôm sau
            let tomorrow_start = moment();
            tomorrow_start.add(1, "days");
            tomorrow_start.set("hour", 6);
            tomorrow_start.set("minute", 0);
            tomorrow_start.set("second", 0);
            return tomorrow_start;
        } else if (today.hours() < 6) {
            let tomorrow_start = moment();
            tomorrow_start.set("hour", 6);
            tomorrow_start.set("minute", 0);
            tomorrow_start.set("second", 0);
            return tomorrow_start;
        } else if (today.hours() < parseInt(openTimeKeno[0])) { // Chưa đến giờ bán Keno -> Chờ đến giờ bán
            let tomorrow_start = moment();
            tomorrow_start.set("hour", parseInt(openTimeKeno[0]));
            tomorrow_start.set("minute", parseInt(openTimeKeno[1]));
            tomorrow_start.set("second", parseInt(openTimeKeno[2]));
            return tomorrow_start;
        } else {
            let minute = today.minutes();
            let next = moment();
            next.set("seconds", 0);

            if (minute % 10 >= (10 - lotteryConfig.KENO_TIME_LIMIT)) {
                next.add("minutes", 10);
            }

            do {
                next.add("minutes", 1);
            } while (next.get("minutes") % 10 !== 0);

            next.subtract("minutes", lotteryConfig.KENO_TIME_LIMIT);
            next.set("seconds", 0);
            return next;


            // if (minute % 10 === 0 && second === 0) { // Đúng giờ quay
            //     return today;
            // } else {
            //     let pinMinute = minute % 10 >= 8; // Phút thứ 8 đến
            //     if (pinMinute) {
            //         today.add(10, "minute")
            //     }
            //     do {
            //         minute++;
            //     } while (minute % 10 !== 0);
            //     today.add(minute - today.minutes() + (pinMinute ? 10 : 0), "minutes");
            //     if (pinMinute && minute === 60) {
            //         today.subtract(1, "hours");
            //     }
            //     today.subtract(second, "seconds");
            //     today.subtract(lotteryConfig.KENO_TIME_LIMIT, "minutes");
            //     return today;
            // }
        }
    }

    if (today.get("hour") > 18) {
        today.add(1, "days");
    } else if (today.get("hour") === 18) {
        if (today.get("minute") > 0) {
            today.add(1, "days");
        } else {
            if (today.get("second") > 0) {
                today.add(1, "days");
            }
        }
    }
    let day = today.day();
    today.set("hour", 18);
    today.set("minute", 0);
    today.set("second", 0);
    if (type === "mega645") {
        if (![3, 5, 7].includes(day)) { // 4, 6, CN
            if (day < 3)
                today = today.add(3 - day, "days");
            else if (day < 5)
                today = today.add(5 - day, "days");
            else if (day < 7)
                today = today.add(7 - day, "days");
            return today;
        }
        return today;
    } else if (type === "power655") {
        if (![2, 4, 6].includes(day)) { // 3, 5, 7
            if (day > 6)
                today = today.add(3, "days");
            else if (day < 4)
                today = today.add(4 - day, "days");
            else if (day < 6)
                today = today.add(6 - day, "days");
            return today;
        }
        return today;
    } else if (type === "max3d") {
        if (![1, 3, 5].includes(day)) { // 2, 4, 6
            if (day > 5)
                today = today.add(8 - day, "days");
            else if (day < 3)
                today = today.add(3 - day, "days");
            else if (day < 5)
                today = today.add(5 - day, "days");
            return today;
        }
        return today;
    } else if (type === "max4d") {
        if (![2, 4, 6].includes(day)) { // 3, 5, 7
            if (day > 6)
                today = today.add(3, "days");
            else if (day < 4)
                today = today.add(4 - day, "days");
            else if (day < 6)
                today = today.add(6 - day, "days");
            return today;
        }
        return today;
    }
}

export function isExpiredKeno() {
    let state = {};
    try {
        state = JSON.parse(localStorage.getItem("state"));
        if (state) {
            if (typeof (state.lotteryConfig) === "object") {
                lotteryConfig = {
                    ...lotteryConfig,
                    ...state.lotteryConfig
                }
            }
        }
    } catch (e) {

    }

    let currentTime = [moment().get('hours'), moment().get('minutes'), moment().get('seconds')];

    let openTime = lotteryConfig.OPEN_TIME.split(':');
    openTime = openTime.map((x) => parseInt(x));
    let closeTime = lotteryConfig.CLOSE_TIME.split(':');
    closeTime = closeTime.map((x) => parseInt(x));
    // Check out
    if (currentTime[0] > closeTime[0]) {
        return lotteryConfig.OPEN_TIME;
    }

    if (currentTime[0] === closeTime[0]) {
        if (currentTime[1] > closeTime[1]) {
            return lotteryConfig.OPEN_TIME;
        }
        if (currentTime[1] === closeTime[1]) {
            if (currentTime[2] >= closeTime[2]) {
                return lotteryConfig.OPEN_TIME;
            }
        }
    }

    // checkin
    if (currentTime[0] < openTime[0]) {
        return lotteryConfig.OPEN_TIME;
    }

    if (currentTime[0] === openTime[0]) {
        if (currentTime[1] < openTime[1]) {
            return lotteryConfig.OPEN_TIME;
        }
        if (currentTime[1] === openTime[1]) {
            if (currentTime[2] <= openTime[2]) {
                return lotteryConfig.OPEN_TIME;
            }
        }
    }

    return false;
}

export function deepCompareObj(x, y) {
    return JSON.stringify(x) === JSON.stringify(y)
}

export function filterUserInfo(data) {
    try {
        let user = {};
        ['id', 'isActive', 'fullName', 'gender', 'phoneNumber', 'address', 'idCardNumber', 'walletId', 'orderShoppingCartId', 'cityId', 'districtId', 'email', 'balance', 'availableBalance', 'authToken', 'winingBalance'].forEach(key => {
            user[key] = data[key];
        });
        if (data.wallet) {
            user.balance = data.wallet.balance;
            user.winingBalance = data.wallet.winingBalance
            user.availableBalance = data.wallet.availableBalance;
        }
        return user;
    } catch (e) {
        return null;
    }

}

export function safeUrlImage(path) {
    if (isEmpty(path))
        return null;
    return require("../config/index").root + `/media/` + path;
}
export function safeUrlImageIloto(path,productId) {
    if (isEmpty(path)) {
        return null;
    }
    switch (productId) {
        case 13:
            return require('../config/index').apiThirdParty + `/media/` + path;
            case 14:
            return require('../config/index').rootIloto + `/media/` + path;
    }
}
export function combination(k, n) {
    if (k > n) return 1;
    if (k < 0) return 1;
    if (n < 0) return 1;
    if (n > 15) return 1;
    if (k == 0 || k == n) return 1;
    if (k == 1) return n;
    return combination(k - 1, n - 1) + combination(k, n - 1);
}

export function generateCombination(k, n) {
    let result = [];
    let th = Array(n + 1).fill(0);
    let stop = 0;
    for (let i = 1; i <= k; i++) {
        th[i] = i;
    }
    while (!stop) {
        result.push(JSON.parse(JSON.stringify(th)));
        let i = k;
        while (i > 0 && th[i] === n - k + i) i--;
        if (i > 0) {
            th[i]++;
            for (let j = i + 1; j <= k; j++) {
                th[j] = th[i] + j - i;
            }
        } else {
            stop = 1;
        }
    }
    return result;
}

export default {
    isEmpty: isEmpty,
    formatValue: formatValue,
    copyState: copyState,
    generateCountDownDate: generateCountDownDate,
    deepCompareObj,
    filterUserInfo,
    safeUrlImage,
    alert,
    confirm,
    combination,
    generateCombination,
    isExpiredKeno,
    fetchSeo,
    safeUrlImageIloto,
}
