import path, {root,rootIloto,apiThirdParty} from "./path";


const config = {
    path: path
};

export default config;

export {path, root,rootIloto,apiThirdParty}
